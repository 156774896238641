import React, { FC } from "react";
import { RouteComponentProps, NavLink, useHistory } from "react-router-dom";
import { Routes } from "../../../../AppRoutes";
import { Icon } from "../../../../components";
import { useSelector } from "../../../../store";

export const TradeLayout = (Component: FC<any>) => {
  const LayoutComponent = (props: any) => {
    const user = useSelector((state) => state.user);
    const history = useHistory();

    if (!["SUPER_ADMIN", "ADMIN"].includes(user?.userRole)) {
      history.replace(Routes.dashboard.path);
      return null;
    }

    return (
      <div className="trade-layout-compnt">
        <div className="TabLinks">
          {/* <NavLink to={Routes.candleControlSetting.path} exact={true}>
            <Icon.Setting />
            Candle Control Setting
          </NavLink> */}

          <NavLink to={Routes.intervention.path} exact={true}>
            <Icon.Setting />
            Intervention
          </NavLink>

          <NavLink to={Routes.slippage.path} exact={true}>
            <Icon.Setting />
            Slippage
          </NavLink>

          <NavLink to={Routes.bridgeSystem.path} exact={true}>
            <Icon.Setting />
            Bridge System
          </NavLink>

          {/* <NavLink to={Routes.leverageSetting.path} exact={true}>
                      <Icon.Setting />
                      Leverage Setting
                  </NavLink> */}
        </div>
        <Component {...props} />
      </div>
    );
  };
  return LayoutComponent;
};
