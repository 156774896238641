import React, { useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, Icon, InputWraper, Table, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withFranchiseWraper } from "../wraper";
import moment from "moment";
import { PopupWraper } from "../../../components/popup";
import * as Yup from 'yup';
import { translate } from "../../../languages";
import { InputText } from "../../../components";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { useSelector } from "../../../store";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";

export const PageFranchiseCommissionSetting = withFranchiseWraper(() => {

    const user = useSelector((state) => state.user);
    const history = useHistory();
    if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
        history.replace(Routes.dashboard.path);
        return null;
    }
    
    const [params, setParams] = useState<any>();
    const [isShowAddUserPopup, setIsShowAddUserPopup] = useState<boolean>(false);
    const [dataRemoveUser, setDataRemoveUser] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

    const { handleSubmit, getInputProps } = useForm({
        structure: [
            {
                name: "userName",
                validate: Yup.string()
                    .required(translate('must-be-provided'))
            }
        ],
        onSubmit: async (values) => {
        let payload = {
            userName: values?.userName
        }
        return AdminService.submitUserToDisableCommision(payload).then(async () => {
                setForceUpdateTable(state => !state);
                CreateAlert({message: "Add user to disable commission successfully", type: "success"});
            }).catch((error:any) => {
                CreateAlert({message: translate(error.message), type: "danger"});
            }).finally(() => {
                setIsShowAddUserPopup(false);
            });
        }
    });
    
    let structure = [
        {
            name: "Username",
            key: "userName",
        },
        {
            name: "Email",
            key: "email",
            render: (item: any) => <span className="email">{item.email}</span>,
        },
        {
            name: "Display Name",
            key: "firstName",
        },
        {
            name: "Created Date",
            key: "created",
            render: (item: any) => moment(item?.created).format("L HH:mm:ss"),
        },
        {
            name: "Action",
            render: (item:any) => {
                return (
                    <div className="action">
                        <div
                            title="remove"
                            className="action__remove"
                            onClick={() => setDataRemoveUser(item)}
                        >
                            <Icon.ActionRemoveIcon />
                        </div>
                    </div>
                );
            },
        },
    ];

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getCommissionSettingList({
                    ...params,
                    page: 1,
                    pageSize: 10000,
                });

                const fileHead = structure.map((v) => v.name);
                fileHead.pop(); //remove last column on table
                
                const dataExport = [
                    fileHead,
                    ...response?.result?.data?.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                            return item[column.key];
                        }),
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(wb, `Commission Setting ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, { type: "binary" });

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    let handleOnClickConfirmPopup = () => {
        if (isLoading === true) return;
        setIsLoading(true);
        AdminService.removeUserToEnableCommission(dataRemoveUser?.userId)
            .then(async () => {
                setForceUpdateTable(state => !state);
                CreateAlert({type: 'success', message: 'Remove user successfully'});
            })
            .catch((error:any) => {
                CreateAlert({message: translate(error.message), type: "danger"});
            })
            .finally(() => {
                setDataRemoveUser(null);
                setIsLoading(false);
            });
    }

    return (
        <div className="page-franchise-commission-setting">
            <div className="button-container">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="success"
                    onClick={handleExportExcel}
                />
                <Button
                    className="mb20"
                    label="Add User"
                    buttonType="success"
                    icon={Icon.AddNewIcon}
                    onClick={() => setIsShowAddUserPopup(true)}
                />
            </div>
            <Table
                hasOrderColumn
                filters={[
                    {
                        name: "Search",
                        key: "searchString",
                        input: TableFilterInputText,
                    },
                    {
                        name: "Date",
                        key: "created",
                        input: (e) => (
                            <TableFilterRangeTimeInput
                                {...e}
                                fromKey="fromDate"
                                toKey="toDate"
                            />
                        ),
                        defaultValue: {
                            fromDate: moment().startOf("month"), 
                            toDate: moment().endOf("day")
                        },
                    },
                ]}
                structure={structure}
                forceUpdateTable={forceUpdateTable}
                fetchData={async (state) => {
                    setParams(state);
                    return AdminService.getCommissionSettingList({
                        ...state,
                        page: state.pageNumber,
                        pageSize: state.limit,
                    }).then((res) => {
                        return {
                            count: res?.result?.total,
                            data: res?.result?.data,
                        };
                    });
                }}
            />
            {isShowAddUserPopup && (
                <PopupWraper center title="Add user to disable" onClose={() => setIsShowAddUserPopup(false)}>
                    <InputWraper
                        label={"Username"}
                        inputProps={getInputProps("userName")}
                        component={InputText}
                    />
                    <Button
                        label="Submit"
                        onClick={handleSubmit}
                        isMiddle
                        buttonType="success"
                    />
                </PopupWraper>
            )}
            {dataRemoveUser ? (
                <GeneralPopupCompnt
                    onClose={() => {
                        setDataRemoveUser(null);
                    }}
                    onClickConfirm={handleOnClickConfirmPopup}
                    textButton="OK"
                    titlePopup={'WARNING!'}
                    messagePopup={['Are you sure you want to remove Username: ', <span style={{color: '#4680ff'}}>{dataRemoveUser?.userName}</span>, ', Email: ', <span style={{color: '#4680ff'}}>{dataRemoveUser?.email}</span>]}
                />
            ) : null}
        </div>
    );
});
