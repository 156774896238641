import moment from "moment";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, InputWraper, NumberUtils, ObjectUtils, Table, useForm } from "../../../modules";
import { BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withFranchiseWraper } from "../wraper";
import { ENetWork, EUserRole } from "../../../types";
import { PopupWraper } from "../../../components/popup";
import { InputText } from "../../../components";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
type Props = {};

const PageFranchiseTicketBonus = withFranchiseWraper((props: Props) => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }
  
  const coinsState = useSelector((state) => state.coins);
  const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
    arr.map((item: any) => ({
      label: item.coinId === 7 ? "Ticket" : item.name,
      value: item.coinId,
    }))
  );
  const [currentData, setCurrentData] = useState(null as any);
  const [totalAmount, setTotalAmount] = useState<any>();
  const [params, setParams] = useState<any>();
  const [isShowTransferTicketBonus, setIsShowTransferTicketBonus] = useState(false);

  // const startOfMonth = moment().subtract(1, "week").startOf("week");
  const startOfMonth = moment().startOf("week").add(1, "day");
  const endOfDay = moment().endOf("day");

  let transactionTypeList = BankService.getTransactionTypeOptions();

  const { handleSubmit, getInputProps, isSubmitting, resetForm } = useForm({
      enableReinitialize: true,
      structure: [
        {
          name: "userName",
          validate: Yup.string()
            .required(translate('must-be-provided'))
        },
        {
          name: "ticketBonusAmount",
          validate: Yup.number()
            .typeError(translate('Only number'))
            .required(translate('must-be-provided'))
            .moreThan(0, "Must be > 0")
        }
      ],
      onSubmit: async (values) => {
        let payload = {
            username: values?.userName,
            value: +values?.ticketBonusAmount
        }
        return AdminService.transferTicketBonus(payload).then(async () => {
          CreateAlert({
            message: "Transfer Ticket Bonus successfully", 
            type: "success"
          });
        }).catch((error:any) => {
          CreateAlert({message: translate(error.message), type: "danger"});
        }).finally(() => {
          setIsShowTransferTicketBonus(false);
          resetForm();
        });
      }
  });

  return (
    <div className="franchise-ticket-bonus">
      <div className="button-container">
        <Button 
          className="flex-grow-0 mr12"
          label="Transfer Ticket Bonus"
          buttonType="success"
          onClick={() => setIsShowTransferTicketBonus(true)}
        />
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="success"
          className="mb15 flex-grow-0"
          onClick={async () => {
            const response = await AdminService.getListTransactions({
              ...params,
              coinId: 7,
              transactionTypeId: 66,
              page: 1,
              pageSize: 100000,
            });
            const data = [
              ["Wallets", "Time", "Username", "Amount", "Type"],
              ...response.data.map((item: any) => {
                let row: any[] = [];
                const { balanceAfter, balanceBefore } = item;
                let isIncrease = false;
                if (balanceAfter > balanceBefore) isIncrease = true;
                const hash = ObjectUtils.getIn(item, "transactionHash");
                row.push(
                  ObjectUtils.getIn(
                    coinsOptions.find((v: any) => v.value === item?.coinId),
                    "label",
                    "--"
                  )
                );
                row.push(moment(item?.created).format("L HH:mm:ss"));
                row.push(item?.userName);
                row.push((isIncrease ? "+" : "-") + NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY")));
                row.push(transactionTypeList.find((element: any) => element?.value === item?.transactionTypeId)?.label);
                row.push(hash ? (item.network === ENetWork.BEP20 ? `${getEnv("BSC_SCAN")}${hash}` : `${getEnv("TRON_SCAN")}${hash}`) : null);
                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Ticket-Bonus-${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="flex-grow-1 infor-container">
          <div className="total-amount">
            <div className="total-amount__icon">
              <Icon.TotalPackageIcon />
            </div>
            <div className="total-amount__content">
              ${new Intl.NumberFormat("en").format(totalAmount ?? 0)}
              <span className="sub-text">&nbsp;TOTAL AMOUNT</span>
            </div>
          </div>
        </div>
      </div>

      <Table
        hasOrderColumn
        hasSearchButton
        className="overload"
        filters={[
          {
            name: "Username",
            key: "email",
            input: TableFilterInputText,
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth,
              toDate: endOfDay,
            },
          },
        ]}
        structure={[
          {
            name: "Wallets",
            key: "coinId",
            className: "coin",
            render: (item) => {
              const coin = coinsOptions.find((v: any) => v.value === item.coinId);
              let coinAvatar;
              switch (coin?.value) {
                case 3: {
                  coinAvatar = "usdt.png";
                  break;
                }
                case 7: {
                  coinAvatar = "ticket.png";
                  break;
                }
                case 9: {
                  coinAvatar = "gtc.png";
                  break;
                }
                default: {
                  coinAvatar = "usd.png";
                  break;
                }
              }
              return (
                <>
                  <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
                  <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
                </>
              );
            },
          },
          {
            name: "Time",
            key: "created",
            className: "created",
            render: (item) => moment(item.created).format("L HH:mm:ss"),
          },
          {
            name: "Username",
            key: "userName",
            render: (item) => {
              return (
                <>
                  <span>
                    <CopyToClipboard
                      text={item?.userName}
                      onCopy={() => {
                        CreateAlert({
                          message: translate("Copied username"),
                          type: "success",
                        });
                      }}
                    >
                      <div className="value">
                        <span className="email">{item?.userName}</span>
                        <span className="icon">
                          <Icon.Copy />
                        </span>
                      </div>
                    </CopyToClipboard>
                  </span>
                </>
              );
            },
          },
          {
            name: "Amount",
            key: "value",
            render: (item) => {
              const { balanceAfter, balanceBefore } = item;
              let isIncrease = false;
              if (balanceAfter > balanceBefore) isIncrease = true;
              return (
                <span className={isIncrease ? "positive-value" : "negative-value"}>
                  {isIncrease ? "+" : "-"}
                  {item?.coinId === 7 ? +item?.value : NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                </span>
              );
            },
          },
          {
            name: "Type",
            key: "transactionTypeId",
            render: (item) => transactionTypeList.find((element: any) => element?.value === item?.transactionTypeId)?.label,
          }
        ]}
        fetchData={async (state) => {
          let params = {
            ...state,
            numberOfTransactionsPerPage: state.limit,
          };
          setParams(params);
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListTransactions({
            page: params.pageNumber,
            numberOfTransactionsPerPage: params.limit,
            coinId: 7,
            transactionTypeId: 66,
            ...params
          }).then((res) => {
            console.log("fetchData={ ~ res:", res)
            setCurrentData(res);
            setTotalAmount(res?.total)
            return res;
          });
        }}
      />
      {isShowTransferTicketBonus && (
        <PopupWraper center title="Transfer Ticket Bonus" onClose={() => setIsShowTransferTicketBonus(false)}>
          <InputWraper
              label={"Username"}
              inputProps={getInputProps("userName")}
              component={InputText}
          />
          <InputWraper
              label={"Ticket Bonus Amount"}
              inputProps={getInputProps("ticketBonusAmount")}
              component={InputText}
          />
          <Button
              label="Confirm Transfer"
              onClick={handleSubmit}
              isMiddle
              buttonType="success"
              isLoading={isSubmitting}
              disabled={isSubmitting}
          />
        </PopupWraper>
      )}
    </div>
  );
});

export default PageFranchiseTicketBonus;
